import { axiosProfiClubApiWithCredentials } from '../index';

/* Привязать устройство к пользователю  */
export async function bindDevice(bindRequest) {
  const url = 'profile/devices';

  const response = await axiosProfiClubApiWithCredentials.post(url, { ...bindRequest });

  return response || null;
}
/* /Привязать устройство к пользователю  */
