<template>
  <div class="bonded-devices-bind-view">
    <PageHeader
      class="mb-5"
      :backButtonTo="`/devices`"
    >
      Привязка устройства
    </PageHeader>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="white"
      opacity="0.85"
      spinner-variant="primary"
    >
      <div
        v-if="isSent"
        class="py-4 text-center"
      >
        <b-icon
          icon="check-circle-fill"
          font-scale="3"
          variant="success"
          class="mb-2"
        />
        <div
          v-if="bindedDeviceName !== null"
          class="text-center mb-2"
        >
          {{ bindedDeviceName }}
        </div>
        <b-button
          type="submit"
          @click="onShowForm"
          variant="primary"
          block
          :disabled="isFormButtonDisabled"
        >
          Привязать еще устройство
        </b-button>
      </div>
      <div v-if="$route.query.sn">
        <h6 class="w-100 pr-1 pl-1" v-if="response?.status === 449">
          {{ response?.message }}
        </h6>
        <b-form
          v-if="!isSent"
          @submit.prevent="onSubmitForm"
          class="mt-5"
        >
          <QrReader @serialNumber="onSerialNumber"/>
          <b-form-group
            :label="childDescription"
            label-for="input-serialNumber"
            :invalid-feedback="form.serialNumber.invalidFeedback"
            :state="serialNumberLengthState || form.serialNumber.state"
          >
            <b-form-input
              id="input-serialNumber"
              v-mask="'#### #### #### #### ####'"
              v-model="form.serialNumber.value"
              :state="serialNumberLengthState || form.serialNumber.state"
              trim
              placeholder="Введите серийный номер устройства"
            />
          </b-form-group>
          <!-- /Поле Серийный номер -->

          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="isFormButtonDisabled"
          >
            Привязать устройство
          </b-button>
        </b-form>
      </div>
      <div v-else-if="!isSent">
        <QrReader @serialNumber="onSerialNumber"/>
        <b-form
          @submit.prevent="onSubmitForm"
          class="mt-5"
        >
          <!-- Поле Серийный номер -->
          <b-form-group
            label="Серийный номер"
            label-for="input-serialNumber"
            :invalid-feedback="form.serialNumber.invalidFeedback"
            :state="serialNumberLengthState || form.serialNumber.state"
          >
            <b-form-input
              id="input-serialNumber"
              v-mask="'#### #### #### #### ####'"
              v-model="form.serialNumber.value"
              :state="serialNumberLengthState || form.serialNumber.state"
              trim
              placeholder="Введите серийный номер устройства"
            />
          </b-form-group>
          <!-- /Поле Серийный номер -->

          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="isFormButtonDisabled"
          >
            Привязать устройство
          </b-button>

          <img
            src="../assets/instruction.jpg"
            alt="Инструкция по привязке устройства"
            title="Инструкция по привязке устройства"
            class="bonded-devices-bind-view__instruction"
          >
        </b-form>

        <!-- Ошибка -->
        <b-alert
          show
          v-if="error"
          variant="danger"
          class="mt-4"
        >
          {{ error }}
        </b-alert>
        <!-- /Ошибка -->
      </div>
    </b-overlay>

  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import { bindDevice } from '../api/profi_club/bind_device';
import QrReader from '../components/QrReader.vue';

export default {
  name: 'BindDevice',

  components: {
    PageHeader,
    QrReader,
  },

  computed: {
    isFormButtonDisabled() {
      if (!this.form.serialNumber.value) return true;
      const serialValue = this.form.serialNumber.value.replace(/\D+/g, '');
      return serialValue.length < 16 || (serialValue.length > 16 && serialValue.length < 20);
    },
  },

  data() {
    return {
      isLoading: true,
      isSent: false,
      result: '',
      error: '',
      qrDestroyed: true,

      form: {
        serialNumber: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
      },
      serialNumberLengthState: null,
      bindedDeviceName: null,
      response: null,
      parentDescription: null,
      childDescription: null,
    };
  },

  methods: {
    onSerialNumber(value) {
      this.form.serialNumber = value;
    },

    onShowForm() {
      if (this.$route.query.sn) {
        this.$router.push('bind-device');
      }
      this.isLoading = true;
      this.form.serialNumber = {
        value: '',
        state: null,
        invalidFeedback: null,
      };
      this.isSent = false;
      this.isLoading = false;
      this.bindedDeviceName = null;
      this.response = null;
    },

    async onSubmitForm(event) {
      event.preventDefault();
      this.isLoading = true;
      this.error = null;
      this.serialNumberLengthState = null;

      // Валидация
      if (this.form.serialNumber.value === '') {
        this.form.serialNumber.state = false;
        this.form.serialNumber.invalidFeedback = 'Обязательное поле!';
        return;
      }

      await this.submitForm();
    },

    async submitForm() {
      // Отправка на сервер
      this.isLoading = true;
      const bindRequest = { serialNumber: this.form.serialNumber.value.replace(/\D+/g, '') };

      try {
        const resp = await bindDevice(bindRequest);
        if (resp.status === 200) {
          this.bindedDeviceName = resp.data.device1.deviceName;
          this.response = {
            status: resp.status,
            message: 'Вы успешно привязали устройство!',
          };
          this.isLoading = false;
          this.isSent = true;
        }
      } catch (e) {
        if (e.response.status === 449) {
          await this.$router.push({
            name: 'bind-device',
            query: { sn: this.form.serialNumber.value },
          });
          this.form.serialNumber = {
            value: '',
            state: null,
            invalidFeedback: null,
          };
          this.response = {
            status: e.response.status,
            message: `${e.response.data.message}`,
          };
          this.childDescription = e.response.data.childDescription !== null ? `${e.response.data.childDescription}` : 'Серийный номер второго устройства';
          this.parentDescription = e.response.data.parentDescription !== null ? `${e.response.data.parentDescription}` : 'Серийный номер первого устройства';
          this.isLoading = false;
          this.isSent = false;
        }
        if (e.response.status === 404 || e.response.status === 400) {
          this.$bvToast.toast(
            `${e.response.data}`,
            {
              title: 'Ошибка',
              variant: 'danger',
              autoHideDelay: 10000,
            },
          );
          this.isLoading = false;
          this.isSent = false;
        } else if (e.response.status === 409) {
          this.isLoading = false;
          this.response = {
            status: e.response.status,
            message: e.response.data,
          };
          this.$bvToast.toast(
            `${e.response.data}`,
            {
              title: 'Ошибка',
              variant: 'danger',
              autoHideDelay: 10000,
            },
          );
          this.form.serialNumber.value = '';
          this.form.serialNumber.state = false;
          this.isSent = false;
        }
        this.isLoading = false;
      }
    },
  },

  watch: {
    form: {
      handler(val) {
        this.serialNumberLengthState = null;
        this.error = null;
        const serialNumberCharacterCount = val.serialNumber.value?.split(' ')
          .join('').length;
        if (serialNumberCharacterCount === 16 || serialNumberCharacterCount === 20) {
          this.serialNumberLengthState = true;
        } else {
          this.form.serialNumber.invalidFeedback = 'Обязательное поле!';
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.isLoading = false;
    this.isSent = false;
    if (this.$route.query.sn) {
      this.form.serialNumber.value = this.$route.query.sn;
    }
  },
};
</script>

<style lang="scss">
.bonded-devices-bind-view {
  max-width: 460px;
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;

  &__instruction {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }

  .form-control {
    margin-bottom: 10px;
  }
}
</style>
